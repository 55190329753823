export const SET_PROBLEMS = 'SET_PROBLEMS';
export const SET_COMPETITIONS = 'SET_COMPETITIONS';
export const SET_COMPETITION = 'SET_COMPETITION';
export const CAT_SET_PROBLEMS = 'CAT_SET_PROBLEMS';
export const SET_PROFILE = 'SET_PROFILE';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_PARTICIPANT = 'SET_PARTICIPANT';
export const ADD_PROBLEM = 'ADD_PROBLEM';
export const ADD_EVENT = 'ADD_EVENT';
export const SET_COMPETITIONS_LEVEL_PROBLEMS =
    'SET_COMPETITIONS_LEVEL_PROBLEMS';
export const SET_LEADERBOARD = 'SET_LEADERBOARD';
export const SET_LEADERBOARD_STATUS = 'SET_LEADERBOARD_STATUS';
